<template>
  <v-container>

    <Titulo></Titulo>

    <div style="width: 93%; margin: auto;">
    <NavBar :step="0" />
    <br>
    <v-row  v-if="screen > 800" style="margin: auto; margin-top: -5%;">
      <img :src="casita" alt="Image c" class="casita-img">
      <img :src="banner" alt="Image b" class="banner-img">
    </v-row>

    <v-col v-if="screen < 800" style="margin-top: -27%; margin-bottom: -1%;">
      <img :src="bannerMobile" alt="Image b" class="banner-img" style="margin: auto;">
          <img :src="casitaMobile" alt="Image c" class="casita-img" style="margin-top: -12%;">
      <v-col>
      </v-col>
    </v-col>

    <br>

    <v-stepper :class="vSteperStyle" v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Beneficios
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="step-data">

            <v-form ref="form" v-model="valid" max-witdh="300px">
              <h3 class="text-center text-hola">
                ¡Hola!
              </h3>
              <h3 class="text-center text-tittle">
                ¿Cómo te llamas?
              </h3>
              <br>
              <v-row>
                <v-col>
                  <v-text-field class="input-textField" v-model="userData.name" maxlength="51" :rules="nameRules"
                    label="Nombre" required solo filled @change="change()">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field class="input-textField" v-model="userData.surname" maxlength="51" @change="change()"
                    :rules="surnameRules" label="Apellido" required solo filled>
                  </v-text-field>
                </v-col>
              </v-row>
              <br>
              <br>
              <h3 class="text-center text-tittle">
                ¿Cuál es tu email?
              </h3>
              <h4 class="text-center text-subTittle">
                Así podremos enviarte tu cotización, descuentos y comunicaciones.
              </h4>
              <v-text-field class="input-textField" v-model="userData.email" maxlength="51" @change="change()"
                :rules="emailRules" label="Ingresa tu correo electrónico" required solo filled>
              </v-text-field>
              <br>
              <br>
              <h3 class="text-center text-tittle">
                ¿Cuál es tu teléfono?
              </h3>
              <h4 class="text-center text-subTittle">
                Es para registrar tu contacto.
              </h4>
              <v-row>
                <v-col class="text-center" style="display:flex; justify-content: center;">
                  <v-text-field class="prefijo-telefono" v-model="prefijoTelefono" label="+569" disabled solo filled>
                  </v-text-field>
                  <v-text-field class="input-textFieldPhone" v-model="userData.phoneNumber" maxlength="8" :counter="8"
                    @change="change()" :rules="phoneRules" label="0 000 0000" required solo filled>
                  </v-text-field>
                </v-col>
              </v-row>
              <br>
              <br>
              <h3 class="text-center text-tittle">
                ¿Qué tipo de propiedad quieres asegurar?
              </h3>

              <v-container v-if="screen > 800">
                <v-layout justify-center>
                  <v-radio-group row v-model="userData.tipoPropiedad" :rules="ssRules">
                    <v-layout v-for="option in propiedadOptions" :key="option.id" column
                      style="gap: 10px; text-align: center;margin: auto;">
                      <v-flex>
                        <label class="text-center">{{ option.name }}</label>
                      </v-flex>
                      <v-flex>
                        <v-radio :value="option.value"
                          style="gap: 38px !important; display: inline; margin-left: 20px;"></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-layout>
              </v-container>

              <v-col v-if="screen < 800">
                <v-radio-group :rules="ssRules" v-model="userData.tipoPropiedad" @change="change()" row>
                  <div class="div-radioButtons-1">
                    <v-col>
                      <v-radio class="radioButton" label="Casa" value="casa">
                      </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio class="radioButton" label="Departamento" value="departamento">
                      </v-radio>
                    </v-col>
                  </div>
                </v-radio-group>
              </v-col>

              <h3 class="text-center text-tittle">
                De la propiedad que quieres asegurar, tu eres:
              </h3>

              <v-container v-if="screen > 800" style="position: relative;"> 
                <v-layout justify-center>
                  <v-radio-group v-model="userData.calidadPersona" @change="change(true)" :rules="ssRules" row>
                    <v-layout v-for="option in calidadOptions" :key="option.id" column
                      style="gap: 10px; text-align: center; margin: auto; margin-right: 10px;">
                      <v-flex>
                        <label class="text-center">{{ option.name }}</label>
                      </v-flex>
                      <v-flex>
                        <v-radio :value="option.value" style="gap: 38px !important; display: inline; margin-left: 7px;"></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                  
                  <v-tooltip right >
                    <template v-slot:activator="{ on, attrs }">
                      <img 
                        v-bind="attrs" 
                        v-on="on" 
                        :src="Help" 
                        alt="Ícono de ayuda" 
                        style="width: 24px; height: 24px; cursor: pointer; position: absolute; right: 0; top: 50%; transform: translateY(-50%);" 
                      />
                    </template>
                    <div style="
                      width: 300px;
                      padding: 10px;
                      font-family: Helvetica;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 17px;
                      color: #FFFFFF;
                      background: rgba(0, 0, 0, 0.85);
                      border-radius: 8px;
                      position: relative;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: flex-start;
                      box-shadow: none !important;
                      ">
                      <p style="margin: 0;">Propietario: Proteges tu casa y su contenido.</p>
                      <p style="margin: 0;">Arrendatario: Proteges solo el contenido de tu casa.</p>
                      <p style="margin: 0;">Inversionista: Proteges solo tu casa sin el contenido.</p>

                      <!-- Flecha estilo nube invertida -->
                      <div style="
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -10px;
                        transform: translateY(-50%);
                        width: 0;
                        height: 0;
                        border-right: 10px solid rgba(0, 0, 0, 0.85);
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;">
                      </div>
                    </div>
                  </v-tooltip>

                </v-layout>
              </v-container>

              <v-col v-if="screen < 800">
                <v-radio-group :rules="ssRules" v-model="userData.calidadPersona" @change="change(true)" row>
                  <div class="div-radioButtons-2">
                    <v-col>
                      <v-radio class="radioButton" label="Propietario" value="propietario"></v-radio>
                    </v-col>
                    <v-col>
                      <v-radio class="radioButton" label="Arrendatario" value="arrendatario"></v-radio>
                    </v-col>
                    <v-col>
                      <v-radio class="radioButton" label="Inversionista" value="inversionista"></v-radio>
                    </v-col>
                  </div>
                </v-radio-group>
              </v-col>

              <h3 class="text-center text-tittle">
                Por eso te recomendamos asegurar:
              </h3>

              <v-container v-if="screen > 800">
                <v-layout justify-center>
                  <v-radio-group v-model="userData.recomendacion" :rules="ssRules" @change="change()">
                    <v-layout v-for="option in recomendacionOptions" :key="option.id" column
                      style="gap: 10px; text-align: center; margin: auto; margin-right: 15px;">
                      <v-flex>
                        <label class="text-center">{{ option.name }}</label>
                      </v-flex>
                      <v-flex>
                        <v-radio :value="option.value" style="gap: 38px !important; display: inline; "></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-layout>
              </v-container>

              <v-col v-if="screen < 800">
                <v-radio-group :rules="ssRules" v-model="userData.recomendacion" @change="change()" row>
                  <div class="div-radioButtons-3">
                    <v-col>
                      <v-radio class="radioButton" label="Estructura y Contenidos" value="estructura y contenido"></v-radio>
                    </v-col> 
                    <v-col>
                      <v-radio class="radioButton" label="Sólo contenido" value="contenido"></v-radio>
                    </v-col>
                    <v-col>
                      <v-radio class="radioButton" label="Sólo estructura" value="estructura"></v-radio>
                    </v-col>
                  </div>
                </v-radio-group>
              </v-col>

              <div class="bluebox-recommend">
                <p v-if="userData.recomendacion == 'estructura y contenido'" class="text-recommend">
                  Cubre tu propiedad al 100% es decir, su <br> estructura y todo lo que tienes en ella.
                </p>
                <p v-if="userData.recomendacion == 'contenido'" class="text-recommend">
                  Cubre lo que tienes en tu casa, como muebles, <br>
                  electrónicos, ropa, entre otros. Se excluyen los equipos y <br>
                  materiales que sean de uso profesional.
                </p>
                <p v-if="userData.recomendacion == 'estructura'" class="text-recommend">
                  Cubre sólo el inmueble en si, es decir la construcción <br>
                  de tu casa o departamento en caso de que ocurra un <br> siniestro.
                </p>
              </div>
              <div class="step-data">
                <v-row justify="center">
                  <v-dialog v-model="dialog" width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                      </v-card-title>
                      <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus
                        modificaciones, autorizo expresamente a Southbridge
                        Compañía de Seguros Generales S.A. para que haga tratamiento, almacene, transmita y comunique
                        mis
                        datos
                        personales y la información que le he
                        proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas,
                        sociedades del Grupo Empresarial al que pertenece y
                        terceros prestadores de servicios, estén ubicados dentro o fuera de Chile, con el propósito de
                        que
                        tanto
                        Southbridge Compañía de Seguros Generales
                        S.A. como dichas entidades i) me contacten y pongan a mi disposición, en la forma que estimen
                        pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi
                        información personal para los objetivos del
                        cumplimiento de este Contrato de Seguro; y iii) hagan efectivos el o los convenios que pudieren
                        estar
                        asociados a los seguros que he contratado. Además,
                        por este acto, autorizo expresamente a que cualquier información, comunicación, declaración o
                        notificación que debe efectuar Southbridge Compañía de
                        Seguros Generales S.A. en virtud del presente contrato de seguros sea realizada a través de
                        correo
                        electrónico o de cualquier sistema de transmisión o
                        registro digital de la palabra escrita o verbal o por cualquier otro medio de comunicación
                        fehaciente.
                        El asegurado declara que conoce, acepta y concuerda
                        que cualquier transacción que se realice en el sitio sbseguros.cl con su Rut y clave
                        personal, se
                        entenderá y se reputará, para todos los efectos
                        legales, como realizada por él mismo. En tal sentido, el asegurado se hace entera y totalmente
                        responsable del uso y seguridad de su clave, no cabiéndole
                        Southbridge Compañía de Seguros Generales S.A.. ninguna responsabilidad por el uso indebido de
                        la
                        misma
                        por un tercero distinto del asegurado. El
                        asegurable se hace responsable por la veracidad de la información entregada para la contratación
                        del
                        seguro y del uso de las claves o códigos, liberando a
                        Southbridge Compañía de Seguros Generales S.A. de responsabilidad por cualquier mal uso que
                        hagan
                        terceros de la información que Southbridge
                        Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del asegurable.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn class="accept-button" style="padding: 12px 24px; color: white;" elevation="0" text
                          @click="dialog = false">
                          Aceptar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
                <v-hover v-if="screen > 800">
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323;
                                opacity: 0.8; text-align: justify; max-width: 400px; margin-top: 10%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas que tu información
                        sea utilizada para calcular tu estimación de prima, recomendar un seguro y
                        poder comunicarnos contigo de acuerdo a nuestros nuestros <b>
                          <a style="color:rgb(51, 51, 204);" @click="dialog = true">Términos y Condiciones
                          </a></b>
                      </p>
                    </template>
                  </v-checkbox>
                </v-hover>
                <v-hover v-else>
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323;
                                opacity: 0.8; text-align: justify; margin-top: 10%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas que tu información
                        sea utilizada para calcular tu estimación de prima, recomendar unseguro y
                        poder comunicarnos contigo de acuerdo a nuestros nuestros <b>
                          <a style="color:rgb(0, 44, 119);" @click="dialog = true">Términos y Condiciones
                          </a></b>
                      </p>
                    </template>
                  </v-checkbox>
                </v-hover>
              </div>
              <div class="button-container">
                <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 65%; height: 50px;"
                  @click="validate()">
                  Continuar
                </v-btn>
              </div>

              <br>
              <br>

              <div class="step-data">

                <p class="footer">El riesgo de este seguro es cubierto por</p>

                <img :src="logoSouth" height="70%" width="400" style="display: block; margin: auto;">

              </div>

            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
  </v-container>
</template>

<script>
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import logoSouth from '@/assets/images/LogoFooter3.svg';
import Help from "@/assets/images/help.png";
import banner from "@/assets/images/Banner.svg";
import bannerMobile from "@/assets/images/BannerMobile.svg";
import casita from "@/assets/images/casita.svg";
import casitaMobile from "@/assets/images/casitaMobile.svg";


import CryptoJS from 'crypto-js';

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    banner,
    bannerMobile,
    casita,
    casitaMobile,
    logoSouth,
    Help,
    vSteperStyle: '',
    version: null,
    stepperColor: '#516EB3',
    e1: 1,
    userData: {
      flagAddress: false,
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      addressNumber: null,
      addressDepto: null,
      addressCondo: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      tipoPropiedad: null,
      calidadPersona: null,
      recomendacion: null,
      day: null,
      month: null,
      year: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    dialog: false,
    screen: window.screen.width,
    prefijoTelefono: null,
    valid: false,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s-]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s-]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    benefRules: [(v) => !!v || "Seleccione Beneficiarios"],
    ssRules: [(v) => !!v || "Seleccione una opción"],
    saludRules: [(v) => !!v || "Seleccione una opción"],
    phoneRules: [
      // (v) => !!v || "El número telefónico es requerido",
      (v) => v === "" || /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => v === "" || (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    emailRules: [
      // (v) => !!v || "El correo electrónico es requerido",
      (v) => v === "" || /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => v === "" || v.length <= 50 || "Debe ingresar máximo 50 caracteres"
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
    propiedadOptions: [
      {
        id: 1,
        name: "Casa",
        value: "casa",
      },
      {
        id: 2,
        name: "Departamento",
        value: "departamento",
      },
    ],
    calidadOptions: [
      {
        id: 1,
        name: "Propietario",
        value: "propietario",
      },
      {
        id: 2,
        name: "Arrendatario",
        value: "arrendatario",
      },
      {
        id: 3,
        name: "Inversionista",
        value: "inversionista",
      },
    ],
    recomendacionOptions: [
      {
        id: 1,
        name: "Estructura y Contenidos",
        value: "estructura y contenido",
      },
      {
        id: 2,
        name: "Sólo contenido",
        value: "contenido",
      },
      {
        id: 3,
        name: "Sólo estructura",
        value: "estructura",
      },
    ],
  }),
  async mounted() {

    const query = this.$route.query;
    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

    } else if (userDataAux) {
      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      if (servicesDataAux) {
        const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
        const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
        this.servicesData = JSON.parse(decryptedServicesData);
        // console.log("serivcesData: ", this.servicesData);
      }

    } else {
      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      sessionStorage.setItem('userData', encryptedUserDataLocal);
    }

    if (this.screen > 800) {
      this.vSteperStyle = 'vSteperStyle';
    } else {
      this.vSteperStyle = 'vSteperStyle2';
    }

  },
  methods: {

    async validate() {

      if (this.$refs.form.validate()) {

        this.change();

        const userDataLocal = JSON.stringify(this.userData);
        const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
        sessionStorage.setItem('userData', encryptedUserDataLocal);

        var shieldRes;
        if (this.userData.email && this.activeCampaingUse == 'TRUE') {
          const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

          if (activeCampaingRes.data.data.meta.total == 1) {
            this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
            await ShieldService.updateContactActiveCampain(this.userData, null, this.servicesData, 0);
          } else {
            shieldRes = await ShieldService.createContactActiveCampain(this.userData, this.servicesData);

            if (shieldRes.data.status == 'ok') {
              this.servicesData.contactID = shieldRes.data.data.contacts[0].id;
            }
          }

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

        }

        this.$router.push({ path: "/hg/planes" });

      }
    },
    prefijo() {
      this.prefijoTelefono = '+56'
    },
    async change(autoSelect) {

      try {

        if (autoSelect) {
          let recomendacion = null;
          switch (this.userData.calidadPersona) {
            case "propietario":
              recomendacion = "estructura y contenido";
              break;
            case "arrendatario":
              recomendacion = "contenido";
              break;
            case "inversionista":
              recomendacion = "estructura";
              break;
            // default:
            //   recomendacion = '';
            //   break;
          }

          this.userData.recomendacion = recomendacion;
        }

        const servicesDataAux = sessionStorage.getItem('servicesData');

        if (servicesDataAux) {
          const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
          const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
          this.servicesData = JSON.parse(decryptedServicesData);
          // console.log("serivcesData: ", this.servicesData);
        }

        var carro = await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Inicio');
        this.servicesData.type = 'update';
        this.servicesData.stepId = carro.data.data._id;

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.row div.text-center.col div.v-input.prefijo-telefono.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed div.v-input__control div.v-input__slot div.v-text-field__slot input#input-31 {
  width: 65px !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle2 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.row div.text-center.col div.v-input.prefijo-telefono.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed div.v-input__control div.v-input__slot div.v-text-field__slot input#input-31 {
  width: 65px !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.container div.layout.justify-center div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--column.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.container div.layout.justify-center div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.container div.layout.justify-center div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.step-data div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a.router-link-exact-active.router-link-active div button.v-icon.notranslate.v-icon--link.mdi.mdi-chevron-left.theme--light {
  color: #2D387C !important;
}

.footer {
  color: #333333;
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  opacity: 0.75;
}

.labelSelect {
  color: var(--AD-Texto-1, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.text-hola {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #2D387C;
  mix-blend-mode: darken;
}

.text-tittle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.text-subTittle {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.input-textField {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.input-textFieldPhone {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 0 6px 6px 0;
  /* Aplica radio solo en el lado derecho */
}


.prefijo-telefono {
  box-sizing: border-box;
  max-width: 65px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px 0 0 6px;
}

.radioButton {
  font-size: 40px;
  padding: 2px;
}

.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  height: auto;
  font-size: 16px;
}

.div-radioButtons-3 {
  margin-right: 40%;
}


.bluebox-recommend {
  // height: 80px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(79, 116, 227, 0.08);
  padding: 2%;
}

// @media only screen and (max-width: 767px) {
//   .bluebox-recommend{

// }
// }


.text-recommend {
  color: var(--AD-Primario-1, #2D387C);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-top: 2%;
  line-height: 23.5px;
  /* 146.875% */
}

.accept-button {
  box-sizing: border-box;
  gap: 10px;
  background: #FFB600;
  border: 2px solid #FFB600;
  border-radius: 8px;
  margin-top: -6%;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}
// ------------- //


.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}


.v-btn__content {
  padding: 0.75rem;
}


@media only screen and (max-width: 959.98px) {

  // Evita el mecanismo de esconder las letras del steper
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

.circle-step {
  font-size: 14px;
  width: 10%;
}

.v-stepper__step__step {
  // Este es el step y sus medidas
  height: 40px !important;
  width: 40px !important;
  font-size: 20px !important;
  margin-top: 30%;
  background-size: cover;
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .v-stepper__step__step {
    width: 42px !important;
    height: 42px !important;
    font-size: 20px !important;
    margin-top: 40%;
  }
}

.v-stepper__header {
  // Stepper header - contenedor de steper completo
  box-shadow: none !important;
  align-items: initial !important;
  margin: auto !important;
  flex-wrap: nowrap !important;
  width: 100% !important;
  height: 45px;
}

.v-stepper__label {
  padding: 10%;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .v-stepper__header {
    // Stepper header - contenedor de steper completo
    box-shadow: none !important;
    max-width: 100%;
    align-items: center;
    margin: auto;
    width: 98% !important;
  }
}

.v-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  border: none !important;
  /* Elimina la línea */
  margin: 0 10px;
  /* Espacio entre pasos */
}

.v-divider::before {
  content: '\003E' !important;
  /* Código de la flecha '>' */
  font-size: 24px !important;
  /* Tamaño del icono */
  color: #5C6670 !important;
  /* Color de la flecha */
  font-weight: 300 !important;
}

@media only screen and (max-width: 767px) {
  .v-divider::before {
    content: '\003E' !important;
    /* Código de la flecha '>' */
    font-size: 24px !important;
    /* Tamaño del icono */
    color: #5C6670 !important;
    /* Color de la flecha */
    font-weight: 300 !important;
    // height: 20px !important;
  }
}

.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px #4F74E3 !important;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: #2183f3 !important;
}

.v-stepper__step {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0px !important;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 20px; // el ancho
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .step-data {
    display: inline !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.v-stepperstep {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 30px; // el ancho
  position: relative;
}

.v-stepperheader .v-divider {
  margin: 0 -30px;
}


@media only screen and (max-width: 767px) {
  .circle-step {
    font-size: 12px;
    width: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .v-stepper__header .v-divider {
    margin: 0 -15px;
  }
}

.vSteperStyle {
  max-width: 40%;
  margin: auto;
}

.vSteperStyle2 {
  margin: auto;
}

.v-tooltip__content {
  background: none;
}

.v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
}

// _________________________________________________
// _________________________________________________

.first-col{
  // margin-left: 14%; 
  margin-top: -6%;
}

.second-row{
  text-align: center; 
  justify-content: start; 
  align-items: center; 
  
}

.banner-img{
  width: 100%;
  height: 220px;
  // margin-left: 40%;
  margin-bottom: 3%;
  // margin-right: 14%;
  margin-left: 44%; 
  margin-top: -15%;
}

@media (min-width: 1920px){
  .banner-img{
  width: 100%;
  // margin-left: 40%;
  margin-top: -10%;
  margin-left: 62%;
}
}

// @media only screen and (min-width: 390px) and (max-width: 430px){
//   .banner-img{
//   width: 100%;
//   // margin-left: 40%;
//   }
// }



.casita-img{
  margin-right: 10px;
}

@media (max-width: 950px){
  .casita-img{
    margin-right: 10px;
    margin-left: 6%;
}
}

@media (min-width: 1920px){
  .casita-img{
    margin-left: 2%;
}
}

.seguroHogar-text{
  font-size: 20px;
  color: rgba(0, 44, 119, 1);
}

.text-banner{
  font-size: 13px;
  color: #555555;
}

@media (min-width: 951px) and (max-width: 1200px){
  .text-banner{
    font-size: 10px;
    color: #555555;
  }
}

@media (max-width: 950px){
  .text-banner{
    font-size: 12px;
    color: 555555;

  }
}

// _________________________________________________
// _________________________________________________


// .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
//     display: flex;
//     flex: 1 1 auto;
//     position: relative;
//     margin-right: 50%;
// }
</style>