<template>
  <v-container>

    <Titulo></Titulo>

    <NavBar v-if="loadData == false" :step="3" :petSetup="homeData.infoBenef.seguroMascota" />

    

    <br>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header v-if="screen > 800" style="max-width: 38%; margin: auto;">
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Beneficios
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-header v-if="screen < 800">
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Beneficios
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container step-data">

                <v-col style="padding: 5%;">

                  <h3 class="text-center labelTitulo">
                    Cuéntanos sobre la propiedad que quieres asegurar
                  </h3>

                  <br>
                  <br>

                  <v-row style="padding-bottom: 4%;">
                    <gmap-autocomplete @place_changed="autoCompletado" :rules="addressRules"
                      :placeholder="'Escribe tu dirección'" :class="mapView" style="position: relative;">
                      <!-- <p v-if="campoNoCompletado">Por favor completa la dirección antes de avanzar.</p> -->
                    </gmap-autocomplete>

                    <!-- <input ref="autocompleteInput" type="text" placeholder="Escribe tu dirección">
    <span v-if="!isValidAddress" style="color: red;">{{ validationMessage }}</span> -->
                  </v-row>

                  <br>

                  <div>
                    <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 200px;">
                      <gmap-marker-advanced :position="center" :clickable="true"></gmap-marker-advanced>
                    </gmap-map>
                  </div>

                  <br>
                  <br>

                  <v-row class="form-container">
                    <v-textField required solo filled v-model="homeData.addressHome" :rules="addressRules"
                      maxlength="51" class="v-textField-form" label="Ingresa tu dirección">
                    </v-textField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <v-textField required solo filled v-model="homeData.addressNumberHome" :rules="addressNumberRules"
                      maxlength="51" class="v-textField-form" label="Ingresa el número de la calle">
                    </v-textField>
                  </v-row>

                  <br>

                  <v-row style="margin-top: 2%; ">
                    <v-col>

                      <br>

                      <div :class="condominio">
                        ¿Está en condominio?
                      </div>

                      <br>

                      <v-row class="form-container">
                        <v-radio-group v-model="homeData.condominio" inline :rules="siNoRules">
                          <v-col>
                            Si
                            <v-radio label="" value="Si"></v-radio>
                          </v-col>
                          <v-col>
                            No
                            <v-radio label="" value="No"></v-radio>
                          </v-col>

                          <v-textField v-if="homeData.condominio == 'Si'" solo filled v-model="homeData.nCondominio"
                            label="Número" class="labelCondominio" :rules="nCondominioRules">
                          </v-textField>

                        </v-radio-group>

                      </v-row>

                    </v-col>

                    <v-col style="margin-left: 20%;">

                      <br>

                      <div :class="construccion">
                        La construcción de tu casa es:
                      </div>

                      <br>

                      <v-row class="form-container radioMaterial">
                        <v-radio-group v-model="homeData.material" :rules="tipoContruccionRules">
                          <v-col>
                            Sólida
                            <v-radio label="" value="solida"></v-radio>
                          </v-col>
                          <v-col>
                            Mixta
                            <v-radio label="" value="mixta"></v-radio>
                          </v-col>
                          <v-col>
                            Ligera
                            <v-radio label="" value="ligera"></v-radio>
                          </v-col>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                  </v-row>

                  <br>
                  <br>

                  <h3 class="text-center labelTitulo">
                    ¿Tu casa cumple con todos los siguientes requisitos?
                  </h3>

                  <br>

                  <ul>
                    <li>
                      Es de uso habitacional.
                    </li>
                    <li>
                      No se encuentra deshabitada por más de 30 días corridos.
                    </li>
                    <li>
                      Está ubicada a menos de 15 km de bomberos.
                    </li>
                    <li>
                      Tiene menos de 70 años de construida.
                    </li>
                    <li>
                      No es un local comercial.
                    </li>
                    <li>
                      Propiedad se encuentra en sector urbano.
                    </li>
                    <li>
                      No colinda o no está cercana a terrenos eriazos y/o abandonados, o está a menos de 100 mts de
                      cursos de agua, plantaciones, bosques, fabricas, edificios en construcción, industrias o
                      similares.
                    </li>
                    <li>
                      En caso de incluir cobertura de robo, la propiedad cuenta con al menos 1 de los siguientes
                      requisitos: Reja perimetral, Alarma operativa, Guardia o cuidador permanente, Protección en
                      ventanas y puertas vidriadas, Chapas de seguridad en accesos (no se consideran candados ni cadenas
                      de seguridad).
                    </li>
                  </ul>

                  <br>

                  <v-col style="align-items: center;">
                    <h3 class="text-center labelTitulo">
                      Confirmo que cumple con todos los requisitos
                    </h3>

                    <v-row class="form-container">
                      <v-radio-group v-model="homeData.requisitos" inline :rules="siNoRules">
                        <v-col>
                          Si
                          <v-radio label="" value="Si"></v-radio>
                        </v-col>
                        <v-col>
                          No
                          <v-radio label="" value="No"></v-radio>
                        </v-col>
                      </v-radio-group>
                    </v-row>
                  </v-col>

                  <div class="form-container">
                    <v-btn class="botonContinuar" style="background-color: rgba(0, 44, 119, 1); color: white;"
                      @click="validate()">
                      Continuar
                    </v-btn>
                  </div>
                </v-col>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import CountryData from "@/utils/country-data.js";

import locationService from "../services/location.service";
import ShieldService from "../services/shield.service";

import { gmapApi } from 'vue2-google-maps';

import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

export default {
  name: "Location",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    condominio: '',
    construccion: '',
    mapView: '',

    center: { lat: -33.4489, lng: -70.6693, },
    zoom: 15,
    stepperColor: '#516EB3',
    screen: window.screen.width,

    exclu: false,
    loadData: true,

    userData: {
      flagAddress: false,
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      addressNumber: null,
      addressDepto: null,
      addressCondo: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      tipoPropiedad: null,
      calidadPersona: null,
      recomendacion: null,
      day: null,
      month: null,
      year: null,
    },
    petData: {
      species: {
        name: null,
      },
      breed: null,
      petName: null,
      age: null,
      sex: null,
      hasMicrochip: false,
    },
    homeData: {
      direccion: null,
      addressHome: null,
      addressNumberHome: null,
      communeHome: null,
      communeHomeCode: null,
      regionHome: null,
      regionHomeCode: null,
      condominio: null,
      nCondominio: null,
      requisitos: null,
      material: null,
      country: null,
      center: { lat: -33.4489, lng: -70.6693, },
      nDptoHome: null,
      nPisoHome: null,
      estructuraUF: null,
      contenidoUF: null,
      priceCLP: null,
      priceUF: null,
      couponId: null,
      infoBenef: {
        incendioEstructura: false,
        incendioContenido: false,
        sismoEstructura: false,
        sismoContenido: false,
        roboContenido: false,
        gastosMedicos: false,
        asistenciaMedica: false,
        seguroMascota: false,
      },
      valores: {
        valorIncendioEstructura: null,
        valorIncendioContenido: null,
        valorSismoEstructura: null,
        valorSismoContenido: null,
        valorRoboContenido: null,
        valorGastosMedicos: 1000,
        valorAsistenciaMedica: 1000,
        valorSeguroMascota: 1000,
        valorAsistenciaHogar: 1000,

        valorIncendioEstructuraUF: 0,
        valorIncendioContenidoUF: 0,
        valorSismoEstructuraUF: 0,
        valorSismoContenidoUF: 0,
        valorRoboContenidoUF: 0,
        valorGastosMedicosUF: 0,
        valorAsistenciaMedicaUF: 0,
        valorSeguroMascotaUF: 0,
        valorAsistenciaHogarUF: 0,
      },
      valoresUf: {
        estructuraInput: 1000,
        estructuraSlider: 1000,

        contenidoInput: 0,
        contenidoSlider: 0,
      },
      discount: {
        active: false,
        value: 1,
      }
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },

    e1: 4,
    valid: false,

    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',

    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    nCondominioRules: [
      (v) => !!v || "Debes ingresar la información del condomonio",
    ],
    tipoContruccionRules: [
      (v) => !!v || 'Debes seleccionar un tipo de construcción'
    ],
    siNoRules: [
      (v) => !!v || 'Debes seleccionar una opción'
    ],
    addressNumberRules: [
      (v) => !!v || "Debes ingresar un número de calle",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
  }),
  async mounted() {
    this.initGoogleMaps();

    this.loadData = true;

    const query = this.$route.query;

    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');
    const homeDataAux = sessionStorage.getItem('homeData');

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.homeData = abandoned.homeData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const homeDataLocal = JSON.stringify(this.homeData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();

      if (abandoned.petData) {
        this.petData = abandoned.petData;
        const petDataLocal = JSON.stringify(this.petData);
        const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
        sessionStorage.setItem('petData', encryptedPetDataLocal);
      }

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);
      sessionStorage.setItem('homeData', encryptedHomeDataLocal);

    } else if (userDataAux) {

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesHomeData = CryptoJS.AES.decrypt(homeDataAux, this.secretKey);
      const decryptedDataHome = bytesHomeData.toString(CryptoJS.enc.Utf8);
      this.homeData = JSON.parse(decryptedDataHome);
      // console.log("homeData: ", this.homeData);

    }

    this.loadData = false;

    if (this.screen > 800) {
      this.mapView = 'mapStyle';
      this.condominio = 'labelTituloCondominio';
      this.construccion = 'labelTituloConstruccion';
    } else {
      this.mapView = 'mapStyle2';
      this.condominio = 'labelTituloCondominio2';
      this.construccion = 'labelTituloConstruccion2';
    }
  },
  methods: {
    async initGoogleMaps() {
      await gmapApi();
    },
    back() {
      this.$emit("back");
    },
    async validate() {
      if (this.homeData.direccion == null) {
        swal({
          title: "Campo obligatorio",
          text: "Debes ingresar una dirección",
          icon: "info",
          button: "Ok",
        });
      }
      if (this.homeData.country != 'Chile') {
        swal({
          title: "Error",
          text: "Solo se puede asegurar dentro de Chile",
          icon: "error",
          button: "Ok",
        });
      }
      if (this.homeData.material == 'ligera') {
        swal({
          title: "Lo sentimos",
          text: "Lamentablemente no es posible asegurar una vivienda de material ligero.",
          icon: "error",
          button: "Ok",
        });
      }
      if (this.exclu == true) {
        swal({
          title: "Lo sentimos",
          text: "Lo lamentamos, no es posible asegurar una vivienda en la comuna seleccionada.",
          icon: "error",
          button: "Ok",
        });
      }
      if (this.$refs.form.validate() && this.homeData.country == 'Chile' && this.homeData.material != 'ligera' && this.exclu == false) {

        const homeDataLocal = JSON.stringify(this.homeData);
        const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();
        sessionStorage.setItem('homeData', encryptedHomeDataLocal);

        try {

          await ShieldService.newCreateStep(null, null, this.homeData, this.servicesData, 'Domicilio Casa');

        } catch (error) {
          console.log("Cart Fail");
        }

        if (this.userData.email && this.activeCampaingUse == 'TRUE') {
          const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

          this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

          await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

        }

        if (this.homeData.direccion != null) {
          if (this.homeData.requisitos == "Si") {
            this.$router.push({ path: "/hg/datos" });
          } else if (this.homeData.requisitos == "No") {
            this.$router.push({ path: "/hg/error2" });
          }
        }
      }
    },
    async autoCompletado(place) {
      if (place.geometry && place.geometry.location) {
        this.homeData.direccion = place.formatted_address;
        // const direccionMaps = await this.procesarDireccion(this.homeData.direccion);

        let numeroCalle = null;
        let nombreCalle = null;
        let nombreRegion = null;
        let nombreComuna = null;
        let nombrePais = null;

        // Verifica si el primer componente es de tipo 'street_number'
        if (place.address_components[0].types.includes('street_number')) {
          numeroCalle = place.address_components[0].long_name;
        }

        // Verifica si el primer componente es de tipo 'route' o pasa al siguiente componente
        if (place.address_components[0].types.includes('route')) {
          nombreCalle = place.address_components[0].long_name;
        } else if (place.address_components[1]) {
          nombreCalle = place.address_components[1].long_name;
        }

        // Verifica si hay un componente que corresponde a la región
        for (let component of place.address_components) {
          if (component.types.includes('administrative_area_level_1')) {
            nombreRegion = component.long_name;
            break;
          }
        }

        // Verifica si hay un componente que corresponde a la comuna
        for (let component of place.address_components) {
          if (component.types.includes('administrative_area_level_3')) {
            nombreComuna = component.long_name;
            break;
          }
        }

        // Verifica si hay un componente que corresponde al país
        for (let component of place.address_components) {
          if (component.types.includes('country')) {
            nombrePais = component.long_name;
            break;
          }
        }

        this.homeData.country = nombrePais;

        if (nombreRegion !== null) {
          // Convertir el ID de la región a minúsculas y eliminar espacios en blanco
          let formattedRegionId = nombreRegion
            .toLowerCase()
            .normalize("NFD") // Normalizar para separar sílabas y tildes
            .replace(/[\u0300-\u036f]/g, "") // Eliminar tildes
            .replace("region", "") // Eliminar "región"
            .trim();

          // Buscar la región en el arreglo regionesYComunas
          const regionEncontrada = CountryData.find(region => {
            // Convertir el ID de la región del archivo a minúsculas y eliminar espacios en blanco
            const formattedRegionDataId = region.id.toLowerCase();
            return formattedRegionDataId === formattedRegionId;
          });

          // Si se encontró la región, imprime los detalles de la región
          if (regionEncontrada) {
            // this.userData.region = regionEncontrada; 
            // await this.getRegions(); // Obtener regiones disponibles
            // await this.getCommunes(); // Obtener comunas disponibles

            if (nombreComuna !== null) {
              // Eliminar todo lo que no sean letras de nombreComuna
              const formattedComunaName = nombreComuna
                .toUpperCase()
                .normalize("NFD") // Normalizar para separar sílabas y acentos
                .replace(/[\u0300-\u036f]/g, "") // Eliminar acentos
                .replace(/[^a-zA-Z\s]/g, "") // Eliminar todo lo que no sean letras y espacios
                .trim(); // Eliminar espacios al principio y al final

              // Buscar la comuna dentro de las comunas de la región encontrada
              const comunaEncontrada = regionEncontrada.communes.find(comuna => {
                // Convertir el nombre de la comuna del archivo a minúsculas y eliminar espacios en blanco
                const formattedComunaDataName = comuna.id.toLowerCase();
                // Convertir el nombre de la comuna ingresado a minúsculas y eliminar espacios en blanco
                const formattedInputComunaName = formattedComunaName.toLowerCase().trim();
                return formattedComunaDataName === formattedInputComunaName;
              });

              // Si se encontró la comuna, establecerla como la comuna seleccionada
              if (comunaEncontrada) {
                if (comunaEncontrada.excl === 'si') {
                  this.exclu = true;
                } else {
                  this.exclu = false;
                }

                this.homeData.communeHome = comunaEncontrada;
                this.homeData.communeHomeCode = comunaEncontrada.code;

                this.homeData.regionHome = regionEncontrada;
                this.homeData.regionHomeCode = regionEncontrada.code;

              } else {
                console.log("La comuna ingresada no se encuentra en la región seleccionada.");
              }
            }
          }

        }
        this.homeData.addressHome = nombreCalle;
        this.homeData.addressNumberHome = numeroCalle;
        //ta de mas

        this.center.lat = place.geometry.location.lat();
        this.center.lng = place.geometry.location.lng();
      }
    },
    async change() {

      const location = await locationService.getLocationByCity(this.homeData.direccion);
      this.center.lat = Number(location[0].lat);
      this.center.lng = Number(location[0].lon);

    }
  },
};
</script>

<style lang="scss">
.botonContinuar {
  border-radius: 12px;
  width: 50%;
  height: 120%;
}

.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.labelTituloCondominio {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-left: 25%;
}

.labelTituloCondominio2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-left: 15%;
}

.labelTituloConstruccion {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-left: 5%;
}

.labelTituloConstruccion2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-left: -20%;
}

.labelCondominio {
  max-width: 139px;
  height: 48px;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 10px;
  margin-top: 8%;
  margin-left: 4%;
}

.radioMaterial {
  margin-left: -25%;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #2D387C !important;
}

.v-textField {
  box-sizing: border-box;
  height: 48px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.v-textField-form {
  box-sizing: border-box;
  height: 48px;
  max-width: 250px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelSelect {
  color: var(--AD-Texto-1, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.mapStyle {
  width: 400px;
  height: 48px;
  background: #FFFFFF;
  border: 2px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 15px;
  margin: auto;
}

.mapStyle2 {
  max-width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 2px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 15px;
  margin: auto;
}

// .v-input .v-label {
//   font-size: 12px;
//   color: #8E8E8E;
//   margin-left: 10%;
// }

// #app > div > main > div > div > div.v-stepper.v-sheet.theme--light.elevation-0 > div.v-stepper__items > div > div > form > div > div > div > div:nth-child(4) > input {
//   padding-left: 4%;
// }</style>