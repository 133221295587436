<template>
  <v-container>
    <!-- Imagen para escritorio -->
    <div class="tituloOnco tituloEscritorio">
      <img :src="barraFondo" alt="Imagen Escritorio">
    </div>

    <!-- Imagen para móvil -->
    <div class="tituloOnco tituloMobile">
      <img :src="barraFondoMobile" alt="Imagen Móvil">
    </div>
  </v-container>
</template>

<script>
import Rosa from '@/assets/images/hogar.png'; 
import logoSouth from '@/assets/images/logoSB.png'; 
import barraFondo from '@/assets/images/barraFondo2.svg'; 
import barraFondoMobile from '@/assets/images/barraFondoMobile.svg'; 



export default {
  name: "Titulo",
  data: () => {
    return {
      Rosa,
      logoSouth,
      barraFondo,
      barraFondoMobile
    };
  },
  mounted() { },
};
</script>

<style lang="scss">
.v-container {
  justify-content: center;
  align-items: center;
  /* Agregamos alineación vertical al centro */
}
@media (min-width: 1264px) {
    .container {
        max-width: 97%;
    }
}

/* Estilo principal de la barra */
/* Barra común */
.tituloOnco {
  display: none; /* Ocultar por defecto */
  justify-content: center;
  max-width: 100%;
  overflow: hidden; 
}

/* Imagen de escritorio: mostrar solo en pantallas grandes */
@media (min-width: 601px) {
  .tituloEscritorio {
    display: flex; /* Mostrar en escritorio */
  }
}

.tituloOnco img {
  max-width: 102%;
  height: auto; 
  object-fit: contain;
}

/* Imagen de móvil: mostrar solo en pantallas pequeñas */
@media (max-width: 600px) {
  .tituloMobile {
    display: flex; /* Mostrar en móvil */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
  }

  .tituloMobile img {
    width: 100%;
    height: 127px;
    margin-top: 13%;
  }
}



</style>

