<template>
  <v-container style="margin:auto">
    <br>

    <Titulo></Titulo>
    <NavBar :step="1" />

   

    <br v-if="screen < 800">
  

    <v-col style="margin-top: -3%;">
      <v-stepper v-model="e1" elevation="0">
        <v-stepper-header v-if="screen > 800" style="max-width: 38%; margin: auto;">
          <div class="circle-step">
            <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
              step="1"> Inicio
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3">
              Beneficios
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-header v-if="screen < 800">
          <div class="circle-step">
            <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
              step="1"> Inicio
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3">
              Beneficios
            </v-stepper-step>
          </div>
          <v-divider></v-divider>
          <div class="circle-step">
            <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
            </v-stepper-step>
          </div>
        </v-stepper-header>
        <br v-if="screen < 800">

        <v-stepper-items>
          <v-stepper-content step="2">

            <div>

              <v-form ref="form">

                <div v-if="screen > 800" class="plan-container">
                  <PlanEscritorio :userData="userData" :servicesData="servicesData" :homeData="homeData" />
                </div>

                <div v-if="screen <= 800" class="plan-container">
                  <PlanMovil :userData="userData" :servicesData="servicesData" :homeData="homeData" />
                </div>

              </v-form>

            </div>

            <br>
            <br>


          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </v-col>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    stepperColor: '#516EB3',
    e1: 2,
    valid: false,
    screen: window.screen.width,
    userData: {
      flagAddress: false,
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      addressNumber: null,
      addressDepto: null,
      addressCondo: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      tipoPropiedad: null,
      calidadPersona: null,
      recomendacion: null,
      day: null,
      month: null,
      year: null,
    },
    homeData: {
      direccion: null,
      addressHome: null,
      addressNumberHome: null,
      communeHome: null,
      communeHomeCode: null,
      regionHome: null,
      regionHomeCode: null,
      condominio: null,
      nCondominio: null,
      requisitos: null,
      material: null,
      country: null,
      center: { lat: -33.4489, lng: -70.6693, },
      nDptoHome: null,
      nPisoHome: null,
      estructuraUF: null,
      contenidoUF: null,
      priceCLP: null,
      priceUF: null,
      couponId: null,
      infoBenef: {
        incendioEstructura: false,
        incendioContenido: false,
        sismoEstructura: false,
        sismoContenido: false,
        roboContenido: false,
        gastosMedicos: false,
        asistenciaMedica: false,
        seguroMascota: false,
      },
      valores: {
        valorIncendioEstructura: null,
        valorIncendioContenido: null,
        valorSismoEstructura: null,
        valorSismoContenido: null,
        valorRoboContenido: null,
        valorGastosMedicos: 1000,
        valorAsistenciaMedica: 1000,
        valorSeguroMascota: 1000,
        valorAsistenciaHogar: 1000,

        valorIncendioEstructuraUF: 0,
        valorIncendioContenidoUF: 0,
        valorSismoEstructuraUF: 0,
        valorSismoContenidoUF: 0,
        valorRoboContenidoUF: 0,
        valorGastosMedicosUF: 0,
        valorAsistenciaMedicaUF: 0,
        valorSeguroMascotaUF: 0,
        valorAsistenciaHogarUF: 0,
      },
      valoresUf: {
        estructuraInput: 500,
        estructuraSlider: 500,

        contenidoInput: 100,
        contenidoSlider: 100,
      },
      discount: {
        active: false,
        value: 1,
      }
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    labelDescuento: '',
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
  }),
  async mounted() {

    const query = this.$route.query;

    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');
    const homeDataAux = sessionStorage.getItem('homeData');

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.homeData = abandoned.homeData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const homeDataLocal = JSON.stringify(this.homeData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);
      sessionStorage.setItem('homeData', encryptedHomeDataLocal);

    } else if (userDataAux) {

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      if (homeDataAux) {
        const bytesHomeData = CryptoJS.AES.decrypt(homeDataAux, this.secretKey);
        const decryptedDataHome = bytesHomeData.toString(CryptoJS.enc.Utf8);
        this.homeData = JSON.parse(decryptedDataHome);
        // console.log("homeData: ", this.homeData);
      }

    }

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
.header-hght {
  width: 24% !important;
}

@media only screen and (max-width: 767px) {
  .header-hght {
    width: 50% !important;
  }
}

.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 73%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

#app>div>main>div>div>div.col>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__header {
  max-width: 80%;
}
</style>