<template>
  <v-container>
    <Titulo></Titulo>
    <NavBar :step="3" />

   

    <br v-if="screen < 800">

    <v-stepper class="vSteperStyle" v-model="e1" elevation="0" style="margin: auto; margin-top: -3%;">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Beneficios
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Datos
          </v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="3">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col>
                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                    Cuéntanos sobre tu mascota</h3>

                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Selecciona una opción</h4>

                  <div class="mascota-label" style="max-width: 80%;">

                    <v-col>
                      <v-radio-group required v-model="petData.species" v-on:change="getBreeds()" @change="change()">
                        <v-radio v-for=" (option, index) in speciesOptions" :key="option.id" :value="option"
                          :class="mascotaVista" off-icon on-icon>
                          <template v-slot:label>
                            <div style="display: flex; flex-direction: column; justify-content: center;">
                              <img width="130px" :alt="option.name" :src="option.image" class="species-icon" />
                              <br>
                              <label style="margin:auto;" v-if="index == 0">Perro</label>
                              <v-radio-group v-if="index == 0" v-model="petSelect">
                                <v-radio v-model="selecPPet" style="margin: auto;"></v-radio>
                              </v-radio-group>
                              <label style="margin:auto;" v-if="index == 1">Gato</label>
                              <v-radio-group v-if="index == 1" v-model="catSelect">
                                <br>
                                <v-radio v-model="selecPPet" style="margin: auto;"></v-radio>
                              </v-radio-group>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                  </div>

                  <div v-if="petData.species.id">

                    <v-col>
                      <v-text-field v-model="petData.name" maxlength="51" :rules="nameRules"
                        label="Ingresa el nombre de tu mascota" @change="change()" required solo filled
                        class="v-textField">
                      </v-text-field>
                    </v-col>

                    <br>

                    <v-col>
                      <v-select :disabled="!petData.species" :items="breeds" v-model="petData.breed" :rules="breedRules"
                        @change="change()" item-text="label" label="Selecciona la raza" required solo filled
                        class="v-textField">
                      </v-select>
                    </v-col>

                    <br>

                    <v-col>
                      <v-select :items="ages" v-model="petData.age" :rules="ageRules" :loading="loadingAges"
                        return-object @change="change()" :disabled="loadingAges" item-text="label"
                        label="Selecciona la edad de tu mascota" required solo filled class="v-textField">
                      </v-select>
                    </v-col>

                    <br>

                    <v-col>
                      <v-select :items="sexOptions" v-model="petData.sex" :rules="sexRules" item-text="label"
                        @change="change()" return-object label="Selecciona el géreno de tu mascota" required solo filled
                        class="v-textField">
                      </v-select>
                    </v-col>

                    <br>

                    <!-- <v-col>
                      <h3 class="text-center"
                        style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                        ¿Tiene microchip?</h3>

                      <h4 class="text-center"
                        style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                        No influye en el seguro, pero nos ayuda a recommendarte coberturas y servicios</h4>


                      <v-container>
                        <v-layout justify-center>
                          <v-radio-group autofocus @change="change()" v-model="petData.hasMicrochip" :rules="confirmRules"
                            required row>
                            <v-radio value="true"><template v-slot:label>
                                <div class="forecast-selector">Si</div>
                              </template></v-radio>
                            <v-radio value="false"><template v-slot:label>
                                <div class="forecast-selector">No</div>
                              </template></v-radio>
                          </v-radio-group>

                        </v-layout>
                      </v-container>

                    </v-col> -->

                    <v-col>

                      <div class="button-container">
                        <v-btn
                          style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%; margin: auto;"
                          @click="validate()">
                          Continuar
                        </v-btn>
                      </div>

                    </v-col>

                  </div>

                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>
import catIcon from "@/assets/images/catt2test.png";
import dogIcon from "@/assets/images/doggtest.png";

import catIcon2 from "@/assets/images/catt.png";
import dogIcon2 from "@/assets/images/dogg2.png";

import BreedsService from "../services/breeds.service";
import AgeService from "../services/age.service";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";
import CryptoJS from 'crypto-js';

export default {
  name: "DetailForm",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    vSteperStyle: '',
    mascotaVista: '',
    tipoPropiedad: null,
    stepperColor: '#516EB3',
    petSelect: true,
    catSelect: true,
    valid: false,
    e1: 3,
    stepId: null,
    userData: {
      flagAddress: false,
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      addressNumber: null,
      addressDepto: null,
      addressCondo: null,
      address: null,
      email: null,
      phoneNumber: null,
      hasAcode: null,
      couponId: null,
      dates: null,
      stepId: null,
      tipoPropiedad: null,
      calidadPersona: null,
      recomendacion: null,
      day: null,
      month: null,
      year: null,
    },
    petData: {
      species: {
        name: null,
      },
      breed: null,
      petName: null,
      age: null,
      sex: null,
      hasMicrochip: false,
    },
    homeData: {
      direccion: null,
      addressHome: null,
      addressNumberHome: null,
      communeHome: null,
      communeHomeCode: null,
      regionHome: null,
      regionHomeCode: null,
      condominio: null,
      nCondominio: null,
      requisitos: null,
      material: null,
      country: null,
      center: { lat: -33.4489, lng: -70.6693, },
      nDptoHome: null,
      nPisoHome: null,
      estructuraUF: null,
      contenidoUF: null,
      priceCLP: null,
      priceUF: null,
      couponId: null,
      infoBenef: {
        incendioEstructura: false,
        incendioContenido: false,
        sismoEstructura: false,
        sismoContenido: false,
        roboContenido: false,
        gastosMedicos: false,
        asistenciaMedica: false,
        seguroMascota: false,
      },
      valores: {
        valorIncendioEstructura: null,
        valorIncendioContenido: null,
        valorSismoEstructura: null,
        valorSismoContenido: null,
        valorRoboContenido: null,
        valorGastosMedicos: 1000,
        valorAsistenciaMedica: 1000,
        valorSeguroMascota: 1000,
        valorAsistenciaHogar: 1000,

        valorIncendioEstructuraUF: 0,
        valorIncendioContenidoUF: 0,
        valorSismoEstructuraUF: 0,
        valorSismoContenidoUF: 0,
        valorRoboContenidoUF: 0,
        valorGastosMedicosUF: 0,
        valorAsistenciaMedicaUF: 0,
        valorSeguroMascotaUF: 0,
        valorAsistenciaHogarUF: 0,
      },
      valoresUf: {
        estructuraInput: 500,
        estructuraSlider: 500,

        contenidoInput: 100,
        contenidoSlider: 100,
      },
      discount: {
        active: false,
        value: 1,
      }
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    screen: window.screen.width,
    sexOptions: [
      {
        id: "male",
        label: "Macho",
      },
      {
        id: "female",
        label: "Hembra",
      },
    ],
    selecPPet: false,
    breedRules: [(v) => !!v || "Necesitamos saber la raza de tu mascota"],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    ageRules: [(v) => !!v || "Debes indicar la edad de tu mascota"],
    sexRules: [(v) => !!v || "Selecciona el sexo de tu mascota"],
    microchipRules: [
      (v) => !!v || "Tu mascota debe tener microchip para contratar el seguro",
    ],
    selectedSpecies: null,
    breeds: [],
    speciesOptions: [
      {
        id: "dog",
        name: "Perro",
        image: dogIcon2,
      },
      {
        id: "cat",
        name: "Gato",
        image: catIcon2,
      },
    ],
    ages: [],
    loadingAges: false,
  }),
  async mounted() {

    const query = this.$route.query;

    const petDataAux = sessionStorage.getItem('petData');
    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.petData = abandoned.petData;
      this.homeData = abandoned.homeData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const petDataLocal = JSON.stringify(this.petData);
      const homeDataLocal = JSON.stringify(this.homeData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
      const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);
      sessionStorage.setItem('petData', encryptedPetDataLocal);
      sessionStorage.setItem('homeData', encryptedHomeDataLocal);

    } else if (petDataAux) {
      const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
      const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
      this.petData = JSON.parse(decryptedDataPet);
      // console.log("petData: ", this.petData);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

    } else {

      const petDataLocal = JSON.stringify(this.petData);
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
      sessionStorage.setItem('petData', encryptedPetDataLocal);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

    }

    this.getBreeds();

    this.getAges();

    if (this.screen > 800) {
      this.mascotaVista = 'mascota'
      this.vSteperStyle = 'vSteperStyle';
    } else {
      this.mascotaVista = 'mascota2'
      this.vSteperStyle = 'vSteperStyle2';
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    message() {
      this.$alert("El chip para tu mascota y posterior registro en www.registratumascota.cl, es un requisito para la ley N°21020 de Tenencia Responsable o Ley Cholito. No olvides realizar este proceso.", "Información", "info")
    },
    validate() {
      if (this.$refs.form.validate()) {

        const petDataLocal = JSON.stringify(this.petData);
        const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
        sessionStorage.setItem('petData', encryptedPetDataLocal);

        if (this.userData.tipoPropiedad == "casa") {
          this.$router.push({ path: "/hg/location" });
        } else if (this.userData.tipoPropiedad == "departamento") {
          this.$router.push({ path: "/hg/location2" });
        }
      }


    },
    async getBreeds() {
      if (this.petData.species) {
        // this.petData.breed = null;
        this.breeds = await BreedsService.getBreeds(this.petData.species.id);

        if (this.petData.species.id == 'dog') {
          this.speciesOptions[0].image = dogIcon
          this.speciesOptions[1].image = catIcon2
          this.petSelect = false
          this.catSelect = true
        } else if (this.petData.species.id == 'cat') {
          this.speciesOptions[0].image = dogIcon2
          this.speciesOptions[1].image = catIcon
          this.petSelect = true
          this.catSelect = false
        }
      }
    },
    async getAges() {
      this.loadingAges = true;
      this.ages = await AgeService.getAges();
      this.loadingAges = false;
    },
    async change() {

      try {
        await ShieldService.newCreateStep(null, this.petData, null, this.servicesData, 'Mascotas');
      } catch (error) {
        console.log("Cart Fail");
      }
    },
  }
};

</script>
<style lang="scss">
.mascota {
  width: 100%;
  margin-left: -15%;
  color: white;
}

.mascota2 {
  width: 100%;
  margin-left: -14%;
  color: white;
}

.v-textField {
  box-sizing: border-box;
  min-height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a.router-link-active div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #2D387C !important;
}

.vSteperStyle {
  max-width: 40%;
  margin: auto;
}

// .fixStepper{
//   max-width: 40%;
//   margin: auto;  
// }

.vSteperStyle2 {
  margin: auto;
}

.mascota-label {
  margin-left: -13px;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .mascota-label {
    margin-left: -36px;
    margin-right: 3px;
  }
}
</style>